.full-page-gallery {
  .download-btn {
    font-size: 18px;
  }

  .heading {
    width: 100%;
    height: 50vh;
    max-height: 200px;
    background-image: url("https://i.ibb.co/ZKkBVYS/0T3A6654.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    &.dalat {
      background-image: url("https://i.ibb.co/xfvsP9j/IMGM9307.jpg");
    }

    .heading-title {
      margin-top: 60px;
      font-family: "Alex Brush", sans-serif;
      font-size: 3em;
      color: #fff;
      z-index: 1;
      padding: 0 1em;
      text-align: center;
    }

    .heading-overlay {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      z-index: 1;
    }
  }

  .album-section {
    h1 {
      font-family: "Time New Roman", sans-serif;
      font-size: 2.5em;
      font-weight: 400;
      color: #1a1a1a;
      text-transform: capitalize;
      letter-spacing: 2px;
      margin: 3em 0 0.5em 0;
      text-align: center;
    }

    .masonry {
      padding: 2em;

      img {
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-width: 640px) {
  .full-page-gallery {
    .heading {
      max-height: 260px;

      .heading-title {
        font-size: 4.5em;
      }
    }

    .album-section {
      h1 {
        font-size: 3.5em;
      }

      .masonry {
        padding: 6em;

        img {
          cursor: pointer;
        }
      }
    }
  }
}
