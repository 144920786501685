.countdown-container {
  width: 100%;

  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .date {
    font-family: "Playfair Display";
    font-size: 2.2em;
    font-style: italic;
    letter-spacing: 0.05em;
    margin-bottom: 1em;
  }

  .countdown {
    width: 100%;
    max-width: 340px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .timer {
      max-width: 20%;
      flex: 1;

      padding: 1.2em 0;
      border-radius: 10px;
      background-color: #fff;

      font-style: italic;
      font-weight: 600;

      .number {
        font-family: "Times New Roman", Times, serif;
        font-size: 2.5em;
        color: #925b48;
        margin-top: 0.1em;
        margin-bottom: 0.5em;
      }

      .label {
        font-family: "Playfair Display", serif;
        font-size: 1.6em;
        color: #727376;
        margin-bottom: 0.3em;
      }
    }
  }
}

// ==========================================================================================
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .countdown-container {
    .date {
      font-size: 2.4em;
      margin-bottom: 0.8em;
    }

    .countdown {
      max-width: 400px;

      .timer {
        max-width: 18%;
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .countdown-container {
    .countdown {
      max-width: 450px;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .countdown-container {
    .countdown {
      max-width: 500px;
    }
  }
}

// /* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .countdown-container {
    .countdown {
      max-width: 520px;
    }
  }
}
