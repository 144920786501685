.welcome {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1rem !important;
  min-height: 250px;

  .background-container {
    position: absolute;
    left: 0;

    width: 100%;
    max-width: 140px;

    &.right {
      left: auto;
      right: 0;
    }

    img {
      width: 100%;

      // &.large {
      //   display: none;
      // }
    }
  }

  p {
    font-family: "Playfair Display", serif;
    font-size: 1.8em;
    font-style: italic;
    // color: black;
    color: #625252;
    padding: 5%;
    margin: 0 1em;

    padding: 4%;
    margin: 1em;
    width: 100%;
    max-width: 600px;

    text-align: center;
    letter-spacing: 0.1em;
    line-height: 1.5em;
    z-index: 1;
    background-color: rgba($color: white, $alpha: 0.8);
  }
}

// ==========================================================================================

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .welcome {
    p {
      margin: 0.8em 0;
      line-height: 1.7em;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .welcome {
    min-height: 250px;

    .background-container {
      max-width: 160px;
    }

    p {
      margin: 0;
      background-color: transparent;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .welcome {
    p {
      padding: 0;
      max-width: 60%;
      background-color: transparent;
    }
  }
}

// /* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .welcome {
    p {
      max-width: 60%;
    }
  }
}
