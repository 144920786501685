.rsvp {
  font-size: 1rem !important;

  background-image: url("../../../public/assets/F1_small.jpg");
  background-size: cover;
  background-position: top center;

  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 100vh;
  padding: 4em 2em;
  position: relative;

  form {
    width: 100%;
    max-width: 450px;
    padding: 5em 3em 10em 3em;
    border-radius: 10px;

    background-color: rgba($color: white, $alpha: 0.9);
    box-shadow: 0 2px 10px -3px #d3d3d3;
    position: relative;

    .decoration {
      width: 100%;
      max-width: 23em;

      position: absolute;
      left: 48%;
      bottom: 0;
      transform: translate(-50%, 42%);

      text-align: center;

      img {
        width: 100%;
      }
    }

    p {
      font-family: "Times New Roman", Times, serif;
      font-size: 1.8em;
      font-style: italic;
      line-height: 1.2em;
      letter-spacing: 0.05em;
      margin-bottom: 1.5em;
    }

    fieldset {
      text-align: center;

      input,
      textarea {
        width: 100%;
        outline: 0;
        border: 0;
        border-bottom: 1px solid #808080;
        margin-bottom: 1.5em;
        padding: 0.7em 1em 0.7em 0;
        background: transparent;

        color: #808080;
        font-size: 1.5em;
        font-family: "Times New Roman", Times, serif;

        &::placeholder {
          color: #808080;
        }
      }

      textarea {
        margin-bottom: 0em;
      }

      button {
        background-color: #925b48;
        border: none;
        border-radius: 5px;
        cursor: pointer;

        color: #fff;
        font-size: 1.3em;
        font-weight: 600;
        font-family: "Playfair Display", serif;
        letter-spacing: 0.2em;

        margin-top: 2em;
        padding: 13px 30px;
        transition: 0.3s;

        &:hover {
          filter: brightness(1.1);
        }
      }
    }
  }

  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 10%;
    background-image: linear-gradient(transparent, white);
  }

  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    height: 100%;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    font-style: italic;

    background-color: rgba($color: white, $alpha: 0.95);

    padding: 1em;
    text-align: center;

    div {
      font-size: 2.5em;
    }

    button {
      background-color: #925b48;
      border: none;
      border-radius: 5px;
      cursor: pointer;

      color: #fff;
      font-size: 1.3em;
      font-weight: 600;
      font-family: "Playfair Display", serif;
      letter-spacing: 0.2em;

      margin-top: 3em;
      padding: 13px 30px;
      transition: 0.3s;

      &:hover {
        filter: brightness(1.1);
      }
    }
  }
}

// ==========================================================================================

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .rsvp {
    form {
      padding: 5em 5em 10em 5em;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .rsvp {
    form {
      font-size: 0.9em !important;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .rsvp {
    form {
      font-size: 0.8em !important;
    }
  }
}

// /* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .rsvp {
    justify-content: flex-start;
    background-image: url("../../../public/assets/F1.jpg");
    background-position: top center;
    form {
      margin-left: 8%;
    }
  }
}
