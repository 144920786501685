.gallery {
  font-size: 1rem !important;
  color: #925b48;

  background-image: url("../../../public/assets/C_BG.png");
  background-size: cover;
  background-repeat: none;

  padding: 4em 2em;

  h1 {
    font-family: "Alex Brush", cursive;
    font-size: 4em;
    text-align: center;
    letter-spacing: 0.05em;
    margin-bottom: 1em;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .masonry-wrapper {
    width: 100%;
    max-width: 900px;

    .masonry {
      div {
        img {
          cursor: pointer;
          display: block;
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .button-group {
    width: 100%;
    max-width: 700px;

    display: flex;
    align-items: center;
    justify-content: space-around;

    button {
      background-color: #925b48;
      border: none;
      border-radius: 5px;
      cursor: pointer;

      color: #fff;
      font-size: 1.3em;
      font-weight: 600;
      font-family: "Playfair Display", serif;
      letter-spacing: 0.2em;

      padding: 13px 25px;
      transition: 0.3s;
      margin: 3em 0.5em;

      &:hover {
        filter: brightness(1.1);
      }
    }
  }
}

// ==========================================================================================

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .gallery {
    .button-group {
      button {
        margin-top: 4em;
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .gallery {
    padding: 6em 2em;

    .button-group {
      button {
        font-size: 1em;
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

// /* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
