.story {
  font-size: 1rem !important;
  color: #925b48;

  padding: 3em 2em 5em 2em;

  h1 {
    font-family: "Alex Brush", cursive;
    font-size: 4em;
    text-align: center;
    letter-spacing: 0.05em;
    margin-bottom: 1em;
  }

  .milestone {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    column-gap: 1em;

    .icon {
      width: 4em;
      height: 100%;
      position: relative;
      grid-row: 1 / -1;

      .img-wrapper {
        position: absolute;
        top: 10%;
        width: 4em;
        height: 4em;
        background: #f7f7f7;
        border-radius: 100%;
      }

      img {
        width: 100%;
        height: auto;
        filter: invert(35%) sepia(40%) saturate(544%) hue-rotate(330deg)
          brightness(99%) contrast(87%);
      }

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        width: 0.5em;
        height: 100%;
        background: #f7f7f7;
      }
    }

    .image {
      img {
        width: 100%;
        padding-top: 3em;
      }
    }

    .detail {
      grid-column: 2 / span 1;
      padding-bottom: 5em;

      .heading {
        font-family: "Playfair Display", serif;
        font-size: 2.1em;
        font-style: italic;
        letter-spacing: 0.05em;
        margin-top: 0.5em;
      }

      .time {
        font-family: "Playfair Display", serif;
        font-size: 1.85em;
        font-style: italic;
        letter-spacing: 0.05em;
        margin: 0.6em 0;

        span {
          font-size: 0.9em;
        }
      }

      .text {
        font-family: "Times New Roman", serif;
        font-size: 1.7em;
        line-height: 1.2em;
        color: black;
      }
    }

    &:first-of-type,
    &:last-of-type {
      .icon {
        &::after {
          content: "";
          position: absolute;
          left: 50%;
          transform: translateX(-50%);

          width: 10px;
          height: 10px;
          background: #925b48;
          border: 0.5em solid #f7f7f7;
          border-radius: 100%;
        }
      }
    }

    &:last-of-type {
      .icon {
        &::after {
          bottom: 0;
        }
      }
    }
  }
}

// ==========================================================================================

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .story {
    padding: 6em 2em 8em 2em;

    .story-container {
      .milestone {
        grid-template-columns: 1fr auto 1fr;
        grid-template-rows: auto;
        column-gap: 3em;

        .icon {
          grid-column: 2 / span 1;

          .img-wrapper {
            top: 7em;
          }
        }

        .image {
          text-align: right;

          img {
            max-width: 500px;
          }
        }

        .detail {
          max-width: 500px;
          grid-column: 3 / span 1;
          padding: 3em 0 5em 0;

          .heading {
            margin-top: 0;
          }
        }

        &:nth-child(even) {
          .image {
            text-align: left;
            grid-column: 3 / span 1;
          }

          .detail {
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
            text-align: right;
            justify-self: end;
          }
        }

        &:last-of-type {
          .icon {
            height: 106%;
          }
        }
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .story {
    .story-container {
      .milestone {
        .detail {
          padding: 5em 0 8em 0;
        }
      }
    }
  }
}

// /* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
