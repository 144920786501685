.event {
  font-size: 1rem !important;

  background-image: url("../../../public/assets/C_BG.png");
  background-size: cover;
  background-repeat: none;
  padding: 6em 2em;

  .ceremony {
    color: #925b48;
    display: flex;
    flex-wrap: wrap;

    .name-container {
      display: flex;
      align-items: center;
      margin-bottom: 1em;

      .icon {
        width: 12em;
        height: 12em;
        background-image: url("../../../public/assets/C1.png");
        background-size: cover;
      }

      .name {
        font-family: "Alex Brush", cursive;
        font-size: 3.5em;
        font-style: italic;
        border-right: 2px solid #925b48;
        padding: 0.2em 0.5em 0 0.5em;
      }
    }

    .detail {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .date {
        font-family: "Playfair Display", serif;
        font-size: 2em;
        font-style: italic;
        margin-bottom: 0.8em;
      }

      .location {
        font-family: "Times New Roman";
        font-size: 1.9em;
        color: black;
        font-weight: 600;
        margin-bottom: 0.2em;
      }

      .address {
        font-family: "Times New Roman";
        font-size: 1.6em;
        color: #727376;
        margin-bottom: 0.8em;
      }
    }

    .schedule {
      width: 100%;
      font-style: italic;
      font-weight: 600;

      .time {
        font-family: "Playfair Display", serif;
        font-size: 2.2em;
        margin-right: 0.3em;
      }

      .activity {
        font-family: "Playfair Display", serif;
        font-size: 1.6em;
        color: black;
      }

      & > div {
        margin-bottom: 0.3em;
      }
    }

    &.right {
      margin-top: 2.5em;
      flex-direction: row-reverse;
      text-align: right;

      .name-container {
        flex-direction: row-reverse;

        .icon {
          width: 12em;
          height: 15.55em;
          background-image: url("../../../public/assets/C2.png");
          background-size: cover;
        }

        .name {
          margin-right: 0.2em;
          border-right: 0;
          border-left: 2px solid #925b48;
          padding: 0.3em 0.5em 0.1em 0.3em;
          margin-top: 0.2em;
        }
      }
    }
  }

  .note {
    text-align: right;
    font-family: "Times New Roman", Times, serif;
    font-size: 1.6em;
    color: #727376;
    font-style: italic;
    margin-top: 1em;

    span {
      text-decoration: underline;
      font-weight: 600;
      color: black;
      cursor: pointer;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: black, $alpha: 0.8);
    z-index: 99;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 95%;
        max-width: 950px;
        height: auto;
        z-index: 1;
      }

      div.map {
        width: 95%;
        max-width: 950px;

        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;

        button {
          width: 1.5em;
          height: 1.5em;

          background-color: transparent;
          border: none;
          background-color: white;
          border-radius: 100%;
          font-size: 1.5em;
          line-height: 0;
          cursor: pointer;

          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
        }
      }
    }
  }
}

// ==========================================================================================

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .event {
    .ceremony {
      .name-container {
        .name {
          border-right: 3px solid #925b48;
        }
        margin-right: 1.3em;
      }

      &.right {
        .name-container {
          .name {
            border-left: 3px solid #925b48;
          }
          margin-right: 0;
          margin-left: 1.3em;
        }
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .event {
    padding: 6em 3em;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .event {
    padding: 6em 5em;
  }
}

// /* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .event {
    padding: 6em 8%;
  }
}
