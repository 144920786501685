.carousel-container {
  font-size: 1rem !important;
  position: relative;

  .overlay {
    position: absolute;
    width: 100%;
    height: 120px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), transparent);
    z-index: 1;
  }

  .image-gallery {
    .image-gallery-content {
      .image-gallery-slide-wrapper {
        .image-gallery-swipe {
          .image-gallery-slides {
            .image-gallery-slide {
              .image-gallery-image {
                height: 100vh;
                max-height: 100vh !important;
                min-height: 600px !important;
                object-fit: cover;
              }
            }
          }
        }
      }
    }

    .image-gallery-bullets .image-gallery-bullet:hover,
    .image-gallery-bullets .image-gallery-bullet.active:hover {
      background: #925b48;
      border: 1px solid #925b48;
    }
  }

  .fixed-overlay {
    width: 85%;
    height: 85%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-size: 1em;
    color: white;
    text-align: center;
    pointer-events: none;

    .name-container {
      width: 100%;

      position: absolute;
      top: 20%;
      left: 50%;
      transform: translate(-50%, 0);

      .slogan {
        font-family: "Playfair Display";
        font-size: 2.2em;
        font-style: italic;
        letter-spacing: 0.05em;
        margin-bottom: 0.9em;
      }

      .name {
        font-family: "Alex Brush", sans-serif;
        font-size: 5.5em;
        font-weight: 500;
        line-height: 1.1em;
      }
    }
  }
}

// ==========================================================================================
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .carousel-container {
    .fixed-overlay {
      .name-container {
        .slogan {
          font-size: 2.4em;
        }

        .name {
          font-size: 6em;
        }
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .carousel-container {
    .fixed-overlay {
      .name-container {
        .slogan {
          margin-bottom: 0.5em;
        }
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

// /* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

@media only screen and (orientation: landscape) and (max-height: 600px) {
  .carousel-container {
    .fixed-overlay {
      .name-container {
        top: calc(10% + 30px);
      }
    }
  }
}
