.footer {
  font-size: 1rem !important;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Playfair display", serif;
  padding: 6em 4em 8em 4em;

  position: relative;

  p {
    font-size: 2em;
    font-style: italic;
    color: #925b48;
    letter-spacing: 0.05em;
    line-height: 1.3em;
  }

  div {
    font-family: "Times New Roman", Times, serif;
    position: absolute;
    bottom: 10px;
  }
}
